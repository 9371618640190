// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.comment-form input[type=email], .comment-form input[type=text], .comment-form input[type=url], .comment-form textarea, .form-control, .search-form .search-field {
    display: block;
    width: 100%;
    padding: .8rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #f5f5f5;
    background-image: none;
    background-clip: padding-box;
    border: none;
    border-radius: 0;
    transition: all .15s ease-in-out;
  }

.wpcf7-form-control-wrap {
    position: relative;
    margin-bottom: 30px;
    display: block;
}

.comment-form input[type=email]:focus, .comment-form input[type=text]:focus, .comment-form input[type=url]:focus, .comment-form textarea:focus, .form-control:focus, .search-form .search-field:focus {
    color: #585858;
    background-color: #d2d2d2;
    border-color: #80bdff;
    outline: none;
}


.form-control.wpcf7-not-valid {
background-color: #ffd4d4;

}

span.wpcf7-not-valid-tip {
    text-align: left;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
    border:none;

  }

#media_lib_form {
  strong {
    display: inline-block;
    width: 100%;
  }
}



  $md-checkbox-checked-color: $red;
  $md-checkbox-border-color: rgba(0, 0, 0, 0.54);
  $md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
  $md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

  $md-checkbox-margin: 1em 0;
  $md-checkbox-size: 1.25em;
  $md-checkbox-padding: .25em;
  $md-checkbox-border-width: 2px;
  $md-checkbox-border-radius: 0.125em;
  $md-checkmark-width: 0.125em;
  $md-checkmark-color: #fff;
  $md-checkbox-label-padding: .75em;

  .md-checkbox {
    position: relative;
    margin: $md-checkbox-margin;
    text-align: left;
    display: inline-block;
    margin-right: 10px;

    &.md-checkbox-inline {
      display: inline-block;
    }

    label {
      cursor: pointer;
      display: inline;
      line-height: $md-checkbox-size;
      vertical-align: top;
      clear: both;
      padding-left: 1px;
      &:not(:empty) {
        padding-left: $md-checkbox-label-padding;
      }

      &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
      }

      &:before {
        // box
        width: $md-checkbox-size;
        height: $md-checkbox-size;
        background: #fff;
        border: $md-checkbox-border-width solid $md-checkbox-border-color;
        border-radius: $md-checkbox-border-radius;
        cursor: pointer;
        transition: background .3s;
      }

      &:after {
        // checkmark
      }
    }

    input[type="checkbox"] {
      outline: 0;
      visibility: hidden;
      width: $md-checkbox-size;
      margin: 0;
      display: block;
      float: left;
      font-size: inherit;

      &:checked {
         + label:before{
          background: $md-checkbox-checked-color;
          border:none;
        }
        + label:after {

          $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

          transform: translate($md-checkbox-padding, ($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)) rotate(-45deg);
          width: $md-checkmark-size;
          height: $md-checkmark-size / 2;

          border: $md-checkmark-width solid $md-checkmark-color;
          border-top-style: none;
          border-right-style: none;
        }
      }

      &:disabled {
        + label:before{
          border-color: $md-checkbox-border-color-disabled;
        }
        &:checked {
          + label:before{
            background: $md-checkbox-checked-color-disabled;
          }
        }
      }
    }

  }
